import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import DemoVideoSection from './DemoVideoSection';
import Slider from './slider';
import CourseContentSection from './CourseContentSection';
import reportWebVitals from './reportWebVitals';
import FAQAccordion from './FAQAccordion';
import Footer from './footer';
import FeatureSection from './FeatureSection';
import MobileStickyBar from './MobileStickyBar'; // Import the new MobileStickyBar component

const TrackingScripts = () => {
  useEffect(() => {
    // Facebook Pixel
    (function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = true;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = true;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js'
    );
    window.fbq('init', '5608259589212115');
    window.fbq('track', 'PageView');

    // Microsoft Clarity
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = 'https://www.clarity.ms/tag/' + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, 'clarity', 'script', 'oi5qxwo272');
  }, []);

  return null;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <TrackingScripts /> {/* Add tracking scripts here */}
    <App />
    <FeatureSection />
    <DemoVideoSection />
    <Slider />
    <CourseContentSection />
    <FAQAccordion />
    <Footer />
    <MobileStickyBar /> {/* Add the MobileStickyBar component here */}
  </React.StrictMode>
);

reportWebVitals();
