import React, { useState, useEffect } from 'react';
import { Instagram, Youtube, Mail, ChevronUp, Twitter, Facebook, X } from 'lucide-react';

const Footer = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentPolicy, setCurrentPolicy] = useState('');
  const [policyContent, setPolicyContent] = useState('');
  const [loading, setLoading] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const openPolicyPopup = async (policyType) => {
    setCurrentPolicy(policyType);
    setIsPopupOpen(true);
    await fetchPolicyContent(policyType);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setPolicyContent('');
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      closePopup();
    }
  };

  const fetchPolicyContent = async (policyType) => {
    setLoading(true);
    const urls = {
      'terms-of-use': 'https://others.tevh.in/techvansh/terms-of-service.md',
      'privacy-policy': 'https://others.tevh.in/techvansh/Privacy.md',
      'refund-policy': 'https://others.tevh.in/techvansh/Refund.md'
    };

    try {
      const response = await fetch(urls[policyType]);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const text = await response.text();
      setPolicyContent(text);
    } catch (error) {
      console.error('Error fetching policy:', error);
      setPolicyContent('Failed to load policy. Please try again later or contact support if the issue persists.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isPopupOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isPopupOpen]);

  return (
    <footer className="footer_container">
      <div className="footer_content">
        <div className="footer_scroll-top" onClick={scrollToTop}>
          <ChevronUp size={24} />
        </div>
        <div className="footer_main-section">
          <div className="footer_glow-effect"></div>
          <h2 className="footer_title">Let's Keep the Conversation Going</h2>
          <p className="footer_description">
            Got queries? We've got answers. Reach out to us at <span onClick={() => window.open('https://wa.me/918949514202', '_blank')} className="footer_whatsapp-link">WhatsApp: +918949514202</span>
            <br />Stay updated with exclusive content by following us on social media.
          </p>

          <div className="footer_social-links">
            <a href="https://instagram.com/tech_vansh" target="_blank" rel="noopener noreferrer" className="footer_social-link footer_instagram">
              <Instagram size={24} />
            </a>
            <a href="https://www.youtube.com/techvansh1" target="_blank" rel="noopener noreferrer" className="footer_social-link footer_youtube">
              <Youtube size={24} />
            </a>
            <a href="https://x.com/TECHNICALTRACK1" target="_blank" rel="noopener noreferrer" className="footer_social-link footer_twitter">
              <Twitter size={24} />
            </a>
            <a href="https://www.facebook.com/TechVansh" target="_blank" rel="noopener noreferrer" className="footer_social-link footer_facebook">
              <Facebook size={24} />
            </a>
            <a href="mailto:support@techvansh.in" className="footer_social-link footer_mail">
              <Mail size={24} />
            </a>
          </div>
        </div>
        <div className="footer_divider"></div>
        <div className="footer_info">
          <div className="footer_links">
            <button onClick={() => openPolicyPopup('refund-policy')} className="footer_link">Refund Policy</button>
            <button onClick={() => openPolicyPopup('privacy-policy')} className="footer_link">Privacy Policy</button>
            <button onClick={() => openPolicyPopup('terms-of-use')} className="footer_link">Terms of Use</button>
            
          </div>
          <p className="footer_copyright">
            © {new Date().getFullYear()} TechVansh. All Rights Reserved.
          </p>
        </div>
      </div>

      {isPopupOpen && (
        <div className="policy-popup-overlay" onClick={handleOverlayClick}>
          <div className="policy-popup-content">
            <button className="policy-popup-close" onClick={closePopup}>
              <X size={24} />
            </button>
            <h2 className="policy-popup-title">
              {currentPolicy.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
            </h2>
            {loading ? (
              <div className="policy-popup-loading">
                <div className="policy-popup-loader"></div>
                Loading...
              </div>
            ) : (
              <div className="policy-popup-text">
                {policyContent.split('\n').map((line, index) => {
                  if (line.startsWith('#')) {
                    const headingLevel = line.match(/^#+/)[0].length;
                    const HeadingTag = `h${headingLevel + 1}`;
                    return <HeadingTag key={index}>{line.replace(/^#+\s/, '')}</HeadingTag>;
                  }
                  return <p key={index}>{line}</p>;
                })}
              </div>
            )}
          </div>
        </div>
      )}

      <style jsx>{`
        .footer_container {
          background-color: #0a0a0a;
          color: #ffffff;
          padding: 100px 0 40px;
          font-family: 'Polysans Neutral', sans-serif;
          position: relative;
          overflow: hidden;
        }
        .footer_content {
          max-width: 1200px;
          margin: 0 auto;
          padding: 0 20px;
          position: relative;
          z-index: 1;
        }
        .footer_scroll-top {
          position: absolute;
          top: -25px;
          left: 50%;
          transform: translateX(-50%);
          width: 50px;
          height: 50px;
          background: linear-gradient(135deg, #ff4444 0%, #ff7777 100%);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 0.3s ease;
          box-shadow: 0 4px 10px rgba(255, 68, 68, 0.3);
          border-radius: 10px;
        }
        .footer_scroll-top:hover {
          transform: translateX(-50%) translateY(-5px);
          box-shadow: 0 6px 15px rgba(255, 68, 68, 0.4);
        }
        .footer_main-section {
          background: linear-gradient(135deg, #1a1a1a 0%, #2a2a2a 100%);
          border-radius: 20px;
          padding: 60px 40px;
          text-align: center;
          margin-bottom: 40px;
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
          position: relative;
          overflow: hidden;
        }
        .footer_glow-effect {
          position: absolute;
          top: -50%;
          left: -50%;
          width: 200%;
          height: 200%;
          background: radial-gradient(circle, rgba(255,68,68,0.1) 0%, transparent 70%);
          opacity: 0.5;
          animation: footer_glow 15s infinite linear;
          pointer-events: none;
        }
        @keyframes footer_glow {
          0% { transform: translate(-30%, -30%) rotate(0deg); }
          100% { transform: translate(-30%, -30%) rotate(360deg); }
        }
        .footer_title {
          font-size: 42px;
          font-weight: 800;
          margin-bottom: 25px;
          background: linear-gradient(45deg, #ff4444, #ff7777);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          position: relative;
          display: inline-block;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
        .footer_title::after {
          content: '';
          position: absolute;
          bottom: -10px;
          left: 50%;
          transform: translateX(-50%);
          width: 100px;
          height: 4px;
          background: linear-gradient(45deg, #ff4444, #ff7777);
          border-radius: 2px;
        }
        .footer_description {
          font-size: 18px;
          line-height: 1.8;
          color: #e0e0e0;
          margin-bottom: 35px;
          max-width: 700px;
          margin-left: auto;
          margin-right: auto;
        }
        .footer_whatsapp-link {
          color: #25D366;
          cursor: pointer;
          transition: all 0.3s ease;
        }
        .footer_whatsapp-link:hover {
          text-decoration: underline;
          opacity: 0.8;
        }
        .footer_social-links {
          display: flex;
          justify-content: center;
          gap: 25px;
          flex-wrap: wrap;
        }
        .footer_social-link {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background-color: #333333;
          color: #ffffff;
          transition: all 0.3s ease;
          position: relative;
          overflow: hidden;
        }
        .footer_social-link::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          transition: opacity 0.3s ease;
        }
        .footer_social-link:hover {
          transform: translateY(-5px);
          box-shadow: 0 5px 15px rgba(255, 68, 68, 0.3);
        }
        .footer_social-link:hover::before {
          opacity: 1;
        }
        .footer_social-link svg {
          position: relative;
          z-index: 1;
          transition: transform 0.3s ease;
        }
        .footer_social-link:hover svg {
          transform: scale(1.2);
        }
        .footer_instagram::before { background: linear-gradient(45deg, #405DE6, #5851DB, #833AB4, #C13584, #E1306C, #FD1D1D); }
        .footer_youtube::before { background: linear-gradient(45deg, #FF0000, #FF5722); }
        .footer_twitter::before { background: linear-gradient(45deg, #1DA1F2, #14171A); }
        .footer_facebook::before { background: linear-gradient(45deg, #3b5998, #4c6ef5); }
        .footer_mail::before { background: linear-gradient(45deg, #ff4444, #ff7777); }
        .footer_divider {
          height: 2px;
          background: linear-gradient(to right, transparent, #ff4444, transparent);
          margin-bottom: 40px;
          opacity: 0.5;
        }
        .footer_info {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        }
        .footer_links {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 30px;
          margin-bottom: 25px;
        }
        .footer_link {
          color: #cccccc;
          text-decoration: none;
          font-size: 16px;
          transition: all 0.3s ease;
          position: relative;
          padding: 5px 0;
          background: none;
          border: none;
          cursor: pointer;
        }
        .footer_link::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0;
          height: 2px;
          background: linear-gradient(45deg, #ff4444, #ff7777);
          transition: width 0.3s ease;
        }
        .footer_link:hover {
          color: #ffffff;
        }
        .footer_link:hover::after {
          width: 100%;
        }
        .footer_copyright {
          color: #888888;
          font-size: 14px;
          margin-top: 20px;
        }
        .policy-popup-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.75);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
          backdrop-filter: blur(5px);
          cursor: pointer;
        }
        .policy-popup-content {
          background: linear-gradient(135deg, #1a1a1a 0%, #2a2a2a 100%);
          border-radius: 20px;
          padding: 40px;
          width: 90%;
          max-width: 800px;
          max-height: 80vh;
          overflow-y: auto;
          position: relative;
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
          cursor: default;
        }
        .policy-popup-close {
          position: absolute;
          top: 20px;
          right: 20px;
          background: none;
          border: none;
          color: #ffffff;
          cursor: pointer;
          transition: transform 0.3s ease;
        }
        .policy-popup-close:hover {
          transform: scale(1.1);
        }
        .policy-popup-title {
          font-size: 28px;
          font-weight: 800;
          margin-bottom: 20px;
          background: linear-gradient(45deg, #ff4444, #ff7777);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .policy-popup-text {
          color: #e0e0e0;
          font-size: 16px;
          line-height: 1.6;
          white-space: pre-wrap;
        }
        .policy-popup-text h2 {
          font-size: 24px;
          font-weight: 700;
          margin-top: 20px;
          margin-bottom: 10px;
          color: #ff4444;
        }
        .policy-popup-text h3 {
          font-size: 20px;
          font-weight: 600;
          margin-top: 15px;
          margin-bottom: 8px;
          color: #ff7777;
        }
        .policy-popup-text p {
          margin-bottom: 10px;
        }
        .policy-popup-loading {
          color: #e0e0e0;
          font-size: 18px;
          text-align: center;
          padding: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 15px;
        }
        .policy-popup-loader {
          border: 4px solid #333;
          border-top: 4px solid #ff4444;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          animation: spin 1s linear infinite;
        }
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        @media (max-width: 768px) {
          .footer_container {
            padding: 80px 0 30px;
          }
          .footer_main-section {
            padding: 40px 20px;
          }
          .footer_title {
            font-size: 32px;
          }
          .footer_description {
            font-size: 16px;
          }
          .footer_social-link {
            width: 50px;
            height: 50px;
          }
          .policy-popup-content {
            padding: 30px;
          }
          .policy-popup-title {
            font-size: 24px;
          }
          .policy-popup-text {
            font-size: 14px;
          }
          .policy-popup-text h2 {
            font-size: 20px;
          }
          .policy-popup-text h3 {
            font-size: 18px;
          }
        }
        @media (max-width: 480px) {
          .footer_title {
            font-size: 28px;
          }
          .footer_description {
            font-size: 14px;
          }
          .footer_links {
            flex-direction: column;
            gap: 15px;
          }
          .policy-popup-content {
            padding: 20px;
          }
          .policy-popup-title {
            font-size: 20px;
          }
          .policy-popup-text h2 {
            font-size: 18px;
          }
          .policy-popup-text h3 {
            font-size: 16px;
          }
        }
      `}</style>
    </footer>
  );
};

export default Footer;