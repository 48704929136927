import React, { useRef, useEffect, useState, useCallback } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import AnimatedCTAButton from './AnimatedCTAButton';

const timelinePoints = [
  {
    id: 1,
    title: "Unleash Full Android Control",
    description: "Master ethical hacking techniques to control Android 14 devices both locally and remotely. Gain access to GPS, explore files, and view live screens.",
    imagePath: "https://others.tevh.in/wp-content/uploads/2024/09/1.png"
  },
  {
    id: 2,
    title: "Advanced Payload Binding",
    description: "Learn efficient payload binding techniques, including auto-binding with CRAX RAT, manual binding using CRAX software, and customized binding with APKTool.",
    imagePath: "https://others.tevh.in/wp-content/uploads/2024/09/2.png",
    highlight: "Exclusive Content: Step-by-step video tutorials on various binding methods"
  },
  {
    id: 3,
    title: "Undetectable Payloads (FUD)",
    description: "Create fully undetectable payloads that bypass advanced antivirus software. Ensure your exploits remain hidden while maintaining total device access.",
    imagePath: "https://others.tevh.in/wp-content/uploads/2024/09/3.png"
  },
  {
    id: 4,
    title: "Post-Exploitation Mastery",
    description: "Advance your skills with keylogging, GPS tracking, and undetected file access. Maintain long-term control and gather crucial data after initial access.",
    imagePath: "https://others.tevh.in/wp-content/uploads/2024/09/4.png"
  },
  {
    id: 5,
    title: "Advanced Tools & Exploits",
    description: "Master dropper techniques for accessibility exploitation, safe malware removal, and automated payload installation for seamless Android device control.",
    imagePath: "https://others.tevh.in/wp-content/uploads/2024/09/5.png",
    highlight: "Unique Content: In-depth videos on advanced exploitation techniques"
  },
  {
    id: 6,
    title: "Beginner-Friendly Approach",
    description: "Start your ethical hacking journey with clear, step-by-step tutorials. Build practical skills in Android hacking, from basic concepts to advanced techniques.",
    imagePath: "https://others.tevh.in/wp-content/uploads/2024/09/6.png"
  }
];

const SkeletonLoader = () => (
  <div className="skeleton-loader">
    <div className="skeleton-shine"></div>
  </div>
);

const TimelinePoint = ({ point, isActive, isLeft, isFirst, onInView }) => {
  const pointRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: pointRef,
    offset: ["start end", "center center"]
  });

  useEffect(() => {
    const unsubscribe = scrollYProgress.onChange((latest) => {
      if (latest > 0.5) {
        onInView(point.id);
      }
    });
    return () => unsubscribe();
  }, [scrollYProgress, point.id, onInView]);

  const scale = useTransform(scrollYProgress, [0, 0.5, 1], [0.95, 1, 1]);
  const opacity = useTransform(scrollYProgress, [0, 0.5, 1], [0.7, 1, 1]);
  const xContent = useTransform(scrollYProgress, [0, 0.5, 1], [isLeft ? -30 : 30, 0, 0]);
  const xImage = useTransform(scrollYProgress, [0, 0.5, 1], [isLeft ? 30 : -30, 0, 0]);

  return (
    <motion.div 
      ref={pointRef}
      className={`slider_timelinePoint ${isLeft ? 'left' : 'right'} ${isFirst ? 'first' : ''}`}
      style={{ scale, opacity }}
    >
      <motion.div 
        className={`slider_pointContent ${isActive ? 'active' : ''}`}
        style={{ x: xContent }}
      >
        <h3 className={isActive ? 'slider_glow' : ''}>{point.title}</h3>
        <p>{point.description}</p>
        {point.highlight && <p className="slider_highlight">{point.highlight}</p>}
      </motion.div>
      <motion.div 
        className={`slider_pointImage ${isActive ? 'active' : ''}`}
        style={{ x: xImage }}
      >
        <LazyLoadImage
          src={point.imagePath}
          alt={point.title}
          effect="blur"
          placeholder={<SkeletonLoader />}
          wrapperClassName="slider_lazyImageWrapper"
        />
      </motion.div>
      <motion.div 
        className={`slider_pointNumber ${isActive ? 'active' : ''}`}
        animate={{ 
          scale: isActive ? 1.1 : 1,
          boxShadow: isActive 
            ? "0 0 30px rgba(255, 51, 51, 0.8), 0 0 0 2px #FFFFFF" 
            : "0 0 10px rgba(255, 255, 255, 0.3), 0 0 0 1px rgba(255, 255, 255, 0.5)"
        }}
      >
        {point.id}
      </motion.div>
    </motion.div>
  );
};

const CallToAction = ({ isActive, onMount }) => {
  const ctaRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ctaRef,
    offset: ["start end", "end start"]
  });

  const scale = useTransform(scrollYProgress, [0, 0.5, 1], [0.9, 1, 1]);
  const opacity = useTransform(scrollYProgress, [0, 0.3, 1], [0, 1, 1]);
  const y = useTransform(scrollYProgress, [0, 1], [50, 0]);

  useEffect(() => {
    if (ctaRef.current) {
      onMount(ctaRef.current);
    }
  }, [onMount]);

  return (
    <motion.div 
      ref={ctaRef}
      className={`slider_ctaContainer ${isActive ? 'active' : ''}`}
      style={{ scale, opacity, y }}
    >
      <div className="slider_ctaContent">
        <h2>Master Android Hacking with<br /><span className="slider_brand">CRAX RAT Course</span></h2>
        <AnimatedCTAButton 
  text="Enroll Now - Only ₹1100"
  href="https://web.tevh.in/crax-rat"
  className="slider_ctaButton"
/>
      </div>
    </motion.div>
  );
};

const EnhancedCourseTimelineSlider = () => {
  const [activePoint, setActivePoint] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const containerRef = useRef(null);
  const dotRef = useRef(null);
  const lineRef = useRef(null);
  const [lineHeight, setLineHeight] = useState(0);

  const handlePointInView = useCallback((pointId) => {
    setActivePoint(pointId);
  }, []);

  const handleCtaMount = useCallback((ctaElement) => {
    if (containerRef.current && ctaElement) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const ctaRect = ctaElement.getBoundingClientRect();
      const newLineHeight = ctaRect.top - containerRect.top + ctaRect.height / 2;
      setLineHeight(newLineHeight);
    }
  }, []);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (dotRef.current && containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const scrollPosition = window.scrollY;
        const viewportHeight = window.innerHeight;
        const timelineStart = containerRect.top + scrollPosition;
        const timelineEnd = containerRect.top + lineHeight + scrollPosition;
        const timelineLength = timelineEnd - timelineStart;

        const dotPosition = Math.max(0, Math.min(1, (scrollPosition + viewportHeight / 2 - timelineStart) / timelineLength));
        dotRef.current.style.top = `${dotPosition * 100}%`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lineHeight]);

  return (
    <div className="slider_wrapper">
      <h1 className="slider_headline">Master Android Hacking: Course Journey</h1>
      <div className="slider_container" ref={containerRef}>
        <div className="slider_line" ref={lineRef} style={{ height: `${lineHeight}px` }} />
        <motion.div 
          ref={dotRef}
          className={`slider_dot ${activePoint > 0 ? 'active' : ''}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        />
        {timelinePoints.map((point, index) => (
          <TimelinePoint 
            key={point.id} 
            point={point} 
            isActive={activePoint >= point.id}
            isLeft={isMobile ? false : index % 2 === 0}
            isFirst={index === 0}
            onInView={handlePointInView}
          />
        ))}
        <CallToAction 
          isActive={activePoint > timelinePoints.length} 
          onMount={handleCtaMount}
        />
      </div>

      <style jsx>{`
        .slider_wrapper {
          min-height: 100vh;
          width: 100%;
          background-color: #0a0a0a;
          background-image: 
            radial-gradient(circle at 10% 10%, rgba(255, 0, 0, 0.05) 0%, transparent 50%),
            radial-gradient(circle at 90% 90%, rgba(255, 0, 0, 0.05) 0%, transparent 50%);
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 100px 20px 60px;
          box-sizing: border-box;
        }
        .slider_headline {
          font-size: 56px;
          font-weight: 800;
          color: #FFFFFF;
          text-align: center;
          margin-bottom: 60px;
          text-shadow: 0 0 30px rgba(255, 0, 0, 0.3);
          letter-spacing: 2px;
          font-family: 'Poppins', sans-serif;
        }
        .slider_container {
          position: relative;
          max-width: 1200px;
          width: 100%;
          margin: 0 auto;
          color: #FFFFFF;
          font-family: 'Roboto', sans-serif;
          padding-top: 40px;
          padding-bottom: 100px;
        }
        .slider_line {
          position: absolute;
          left: 50%;
          top: 0;
          width: 2px;
          background: linear-gradient(to bottom, rgba(255, 0, 0, 0.3), rgba(255, 0, 0, 0.1));
          transform: translateX(-50%);
          transition: height 0.3s ease;
        }
        .slider_dot {
          position: absolute;
          left: 50%;
          width: 16px;
          height: 16px;
          background-color: #FF0000;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          z-index: 5;
          transition: all 0.3s ease;
          box-shadow: 0 0 0 4px rgba(255, 0, 0, 0.2);
        }
        .slider_dot.active {
          box-shadow: 0 0 0 8px rgba(255, 0, 0, 0.3), 0 0 20px rgba(255, 0, 0, 0.6);
        }
        .slider_timelinePoint {
          position: relative;
          padding: 80px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .slider_timelinePoint.first {
          padding-top: 20px;
        }
        .slider_timelinePoint.left .slider_pointContent {
          order: 1;
          text-align: right;
          padding-right: 60px;
        }
        .slider_timelinePoint.right .slider_pointContent {
          order: 2;
          text-align: left;
          padding-left: 60px;
        }
        .slider_timelinePoint.left .slider_pointImage {
          order: 2;
        }
        .slider_timelinePoint.right .slider_pointImage {
          order: 1;
        }
        .slider_pointContent {
          width: 45%;
          max-width: 500px;
          background: rgba(30, 30, 30, 0.8);
          padding: 40px;
          border-radius: 20px;
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
          border: 1px solid rgba(255, 0, 0, 0.1);
          transition: all 0.3s ease;
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
          position: relative;
          z-index: 2;
          padding-left: 70px; /* Adjusted padding to prevent overlap */
        }
        .slider_pointContent.active {
          background: rgba(40, 40, 40, 0.9);
          border-color: rgba(255, 0, 0, 0.3);
          box-shadow: 0 15px 40px rgba(255, 0, 0, 0.1);
          transform: translateY(-5px);
        }
        .slider_pointContent h3 {
          margin: 0 0 20px;
          font-size: 28px;
          font-weight: 700;
          color: #FFFFFF;
          transition: all 0.3s ease;
        }
        .slider_pointContent h3.slider_glow {
          color: #FF3333;
          text-shadow: 0 0 15px rgba(255, 51,51, 51, 0.5);
        }
        .slider_pointContent p {
          margin: 0;
          font-size: 18px;
          line-height: 1.6;
          color: #CCCCCC;
        }
        .slider_pointContent .slider_highlight {
          margin-top: 15px;
          font-size: 16px;
          color: #FF3333;
          font-style: italic;
        }
        .slider_pointImage {
          width: 45%;
          max-width: 500px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          border-radius: 20px;
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
          transition: all 0.3s ease;
          position: relative;
          z-index: 1;
        }
        .slider_pointImage.active {
          box-shadow: 0 15px 40px rgba(255, 0, 0, 0.2);
          transform: scale(1.05);
        }
        .slider_lazyImageWrapper {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(20, 20, 20, 0.8);
          border-radius: 20px;
          overflow: hidden;
        }
        .slider_lazyImageWrapper img {
          width: 100%;
          height: auto;
          object-fit: cover;
          transition: all 0.3s ease;
        }
        .slider_pointNumber {
          position: absolute;
          left: 49%;
          width: 60px;
          height: 60px;
          background-color: rgba(30, 30, 30, 0.9);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          font-weight: 700;
          color: #999999;
          transform: translate(-50%, -50%);
          transition: all 0.3s ease;
          box-shadow: 0 0 10px rgba(255, 255, 255, 0.3), 0 0 0 1px rgba(255, 255, 255, 0.5);
          z-index: 3;
        }
        .slider_pointNumber.active {
          background-color: #FF3333;
          color: #FFFFFF;
        }
        .skeleton-loader {
          width: 100%;
          height: 300px;
          background-color: #2a2a2a;
          position: relative;
          overflow: hidden;
          border-radius: 20px;
        }
        .skeleton-shine {
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            90deg,
            transparent,
            rgba(255, 255, 255, 0.1),
            transparent
          );
          animation: shimmer 1.5s infinite;
        }
        @keyframes shimmer {
          0% {
            left: -100%;
          }
          100% {
            left: 100%;
          }
        }
        .slider_ctaContainer {
  position: relative;
  width: 100%;
  max-width: 700px;
  margin: 80px auto 0;
  padding: 60px 40px;
  background: rgba(30, 30, 30, 0.9);
  border-radius: 30px;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: 2px solid rgba(255, 0, 0, 0.2);
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.3), 0 0 40px rgba(255, 0, 0, 0.2);
  transition: all 0.3s ease;
  overflow: hidden;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
        .slider_ctaContainer.active {
          border-color: rgba(255, 0, 0, 0.4);
          box-shadow: 0 30px 80px rgba(255, 0, 0, 0.3), 0 0 60px rgba(255, 0, 0, 0.3);
        }
        .slider_ctaContent {
          text-align: center;
        }
        .slider_ctaContent h2 {
  font-size: 36px;
  font-weight: 800;
  color: #FFFFFF;
  margin-bottom: 30px;
  line-height: 1.4;
  font-family: 'Poppins', sans-serif;
}
        .slider_brand {
  color: #FF3333;
  font-size: 44px;
  text-shadow: 0 0 20px rgba(255, 51, 51, 0.5);
}
        .slider_ctaButton {
  margin-top: 20px;
  display: inline-block !important;
  width: auto !important;
  max-width: 300px !important;
  padding: 16px 32px !important;
  font-size: 20px !important;
  height: 35px !important;
  min-height: 24px !important;
  box-shadow: 0 10px 20px rgba(255, 0, 0, 0.3) !important;
}
.slider_ctaButton:hover {
  transform: translateY(-3px) !important;
  box-shadow: 0 15px 30px rgba(255, 0, 0, 0.4) !important;
}

        @media (max-width: 1024px) {
          .slider_container {
            max-width: 90%;
          }
          .slider_pointContent, .slider_pointImage {
            width: 48%;
          }
        }

        @media (max-width: 768px) {
          .slider_wrapper {
            padding: 80px 20px 40px;
          }
          .slider_headline {
            font-size: 44px;
          }
          .slider_line {
            left: 30px;
          }
          .slider_dot {
            left: 30px;
          }
          .slider_timelinePoint {
            flex-direction: column;
            align-items: flex-start;
            padding: 60px 0 60px 60px;
          }
          .slider_timelinePoint.left .slider_pointContent,
          .slider_timelinePoint.right .slider_pointContent,
          .slider_timelinePoint.left .slider_pointImage,
          .slider_timelinePoint.right .slider_pointImage {
            order: unset;
            width: calc(100% - 60px);
            max-width: none;
            margin-bottom: 30px;
          }
          .slider_timelinePoint.left .slider_pointContent,
          .slider_timelinePoint.right .slider_pointContent {
            text-align: left;
            padding: 30px;
          }
          .slider_pointNumber {
            left: 0;
            width: 50px;
            height: 50px;
            font-size: 22px;
          }
          .slider_ctaContainer {
  max-width: calc(100% - 60px);
  padding: 40px 30px;
  margin-top: 60px;
}
.slider_ctaContent h2 {
  font-size: 30px;
}
.slider_brand {
  font-size: 36px;
}
.slider_ctaButton {
  max-width: 100% !important;
  font-size: 18px !important;
  min-height: 50px !important;
  padding: 14px 28px !important;
}
@media (max-width: 480px) {
  .slider_ctaContainer {
    padding: 30px 20px;
  }
  .slider_ctaContent h2 {
    font-size: 26px;
  }
  .slider_brand {
    font-size: 32px;
  }
  .slider_ctaButton {
    font-size: 17px !important;
    min-height: 46px !important;
    padding: 12px 24px !important;
  }
}
        }



        @media (max-width: 480px) {
          .slider_wrapper {
            padding: 60px 15px 30px;
          }
          .slider_headline {
            font-size: 36px;
          }
          .slider_pointContent h3 {
            font-size: 24px;
          }
          .slider_pointContent p {
            font-size: 16px;
          }
          .slider_pointNumber {
            width: 40px;
            height: 40px;
            font-size: 18px;
          }
          .slider_ctaContent h2 {
            font-size: 28px;
          }
          .slider_brand {
            font-size: 32px;
          }
        }
      `}</style>
    </div>
  );
};

export default EnhancedCourseTimelineSlider;
