import React, { useState, useEffect } from 'react';
import { Clock, Check } from 'lucide-react';
import Cookies from 'js-cookie'; // You need to install js-cookie using 'npm install js-cookie'

const MobileStickyBar = () => {
  const [timeLeft, setTimeLeft] = useState(null);
  const [buttonState, setButtonState] = useState('idle');
  const [isOfferEnded, setIsOfferEnded] = useState(false);

  // Function to get or set the initial visit time in cookies
  const getInitialVisitTime = () => {
    const storedTime = Cookies.get('offerStartTime');
    if (storedTime) {
      return new Date(storedTime);
    } else {
      const now = new Date();
      Cookies.set('offerStartTime', now.toISOString(), { expires: 2 }); // Set cookie to expire in 2 days
      return now;
    }
  };

  // Calculate target date based on the initial visit time (2 days after)
  const getNewTargetDate = (initialTime) => {
    const twoDaysInMs = 2 * 24 * 60 * 60 * 1000; // 2 days in milliseconds
    const nextTarget = new Date(initialTime.getTime() + twoDaysInMs);
    return nextTarget;
  };

  useEffect(() => {
    const initialVisitTime = getInitialVisitTime();
    let targetDate = getNewTargetDate(initialVisitTime);

    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference > 0) {
        return {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / 1000 / 60 / 60) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        };
      } else {
        setIsOfferEnded(true);
        const newStartTime = new Date(); // New cycle after offer ends
        Cookies.set('offerStartTime', newStartTime.toISOString(), { expires: 2 }); // Reset cookie with new 2-day expiration
        targetDate = getNewTargetDate(newStartTime); // Reset timer
        return null;
      }
    };

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleEnrollClick = () => {
    setButtonState('loading');
    setTimeout(() => {
      setButtonState('success');
      setTimeout(() => {
        window.location.href = 'https://web.tevh.in/crax-rat';
      }, 500);
    }, 1500);
  };

  return (
    <div className="mobile-sticky-bar">
      <div className="offer-timer">
        <div className="timer-content">
          <Clock size={14} className="clock-icon" />
          <span className="timer-text">
            {isOfferEnded ? 'Offer ended' : 'Diwali offer ends in:'}
          </span>
          {!isOfferEnded && timeLeft && (
            <div className="timer-countdown">
              {Object.entries(timeLeft).map(([unit, value]) => (
                <div key={unit} className="time-unit">
                  <span className="time-value">{value.toString().padStart(2, '0')}</span>
                  <span className="time-label">{unit.charAt(0)}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="sticky-bar-content">
        <div className="price-info">
          <span className="discounted-price">₹1100</span>
          <span className="original-price">₹2000</span>
        </div>
        <button
          className={`enroll-2204-button ${buttonState}`}
          onClick={handleEnrollClick}
          disabled={buttonState !== 'idle' || isOfferEnded}
        >
          <span className="button-text">
            {isOfferEnded && 'Offer Ended'}
            {buttonState === 'idle' && !isOfferEnded && 'Enroll Now'}
            {buttonState === 'loading' && <div className="loader"></div>}
            {buttonState === 'success' && <Check size={24} />}
          </span>
          <div className="shine"></div>
        </button>
      </div>

      <style jsx>{`
        .mobile-sticky-bar {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #121212;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
          display: none;
          flex-direction: column;
          z-index: 9999;
          box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.2);
          padding-bottom: env(safe-area-inset-bottom, 0);
        }

        .offer-timer {
          background-color: rgba(255, 0, 0, 0.1);
          padding: 8px 12px;
        }

        .timer-content {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
        }

        .clock-icon {
          color: #ff0000;
        }

        .timer-text {
          color: #ff0000;
          font-weight: 600;
          font-size: 14px;
        }

        .timer-countdown {
          display: flex;
          gap: 8px;
        }

        .time-unit {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .time-value {
          background-color: #ff0000;
          color: #FFFFFF;
          padding: 2px 4px;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 700;
          min-width: 24px;
          text-align: center;
        }

        .time-label {
          font-size: 10px;
          color: #ff0000;
          margin-top: 2px;
          text-transform: uppercase;
        }

        .sticky-bar-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 12px 16px;
        }

        .price-info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 23%;
          margin-left: 3px;
        }

        .discounted-price {
          font-size: 22px;
          font-weight: 700;
          color: #31D36C;
          line-height: 1;
        }

        .original-price {
          font-size: 14px;
          color: #AAAAAA;
          text-decoration: line-through;
          margin-top: 4px;
        }

        .enroll-2204-button {
          width: 81%;
          height: 44px;
          font-size: 16px;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          border-radius: 6px;
          background-color: #ff0000;
          color: #FFFFFF;
          border: none;
          cursor: pointer;
          transition: all 0.3s ease;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          overflow: hidden;
          margin-bottom: 4px;
        }

        .enroll-2204-button:disabled {
          background-color: #555555;
          cursor: not-allowed;
        }

        .enroll-2204-button:hover:not(:disabled) {
          background-color: #e60000;
        }

        .enroll-2204-button.loading {
          background-color: #ff3333;
          cursor: wait;
        }

        .enroll-2204-button.success {
          background-color: #31D36C;
        }

        .button-text {
          position: relative;
          z-index: 2;
        }

        .shine {
          position: absolute;
          top: -50%;
          left: -50%;
          right: -50%;
          bottom: -50%;
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.2) 50%,
            rgba(255, 255, 255, 0) 100%
          );
          transform: translateX(-100%) rotate(25deg);
          animation: shineEffect 1.5s infinite linear;
        }

        .loader {
          border: 3px solid #FFFFFF;
          border-top: 3px solid transparent;
          border-radius: 50%;
          width: 24px;
          height: 24px;
          animation: spin 1s linear infinite;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        @keyframes shineEffect {
          0% {
            transform: translateX(-100%) rotate(25deg);
          }
          100% {
            transform: translateX(100%) rotate(25deg);
          }
        }

        @media (max-width: 1023px) {
          .mobile-sticky-bar {
            display: flex;
          }
        }

        @media (max-width: 480px) {
          .offer-timer {
            padding: 6px 10px;
          }

          .timer-text {
            font-size: 12px;
          }

          .time-value {
            font-size: 12px;
            min-width: 20px;
            padding: 2px 3px;
          }

          .time-label {
            font-size: 8px;
          }

          .sticky-bar-content {
            padding: 10px 12px;
          }

          .discounted-price {
            font-size: 24px;
            margin-right: 10px;
          }

          .original-price {
            font-size: 12px;
            margin-left: 8px;
          }

          .enroll-2204-button {
            height: 40px;
            font-size: 14px;
          }
        }
      `}</style>
    </div>
  );
};

export default MobileStickyBar;
