import React, { useState } from 'react';
import { Plus, Minus } from 'lucide-react';

const FAQAccordion = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleQuestion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is CRAX RAT, and why should I learn it?",
      answer: "CRAX RAT is a powerful remote administration tool (RAT) designed for ethical hackers to gain control over Android devices. This course teaches you how to use CRAX RAT to ethically test security vulnerabilities, explore files, capture live screens, and bypass antivirus software. Learning CRAX RAT will equip you with skills that are in high demand for penetration testing and ethical hacking."
    },
    {
      question: "Who is this course suitable for?",
      answer: "This course is designed for beginners and intermediate users who want to enhance their Android hacking skills. Whether you are new to ethical hacking or have some experience, this course will guide you step-by-step through various techniques, from payload binding to advanced post-exploitation methods."
    },
    {
      question: "What are the prerequisites for the course?",
      answer: "To get the most out of this course, you will need basic knowledge of using a Windows PC, basic familiarity with Linux, and proficiency in Hindi (since the course is in Hindi)."
    },
    {
      question: "Is the course recorded or live?",
      answer: "The course is recorded, allowing you to learn at your own pace with the ability to replay any section as many times as needed."
    },
    {
      question: "Are tools or resources included in the course, or do I need to buy them separately?",
      answer: "All required tools and resources are included for free with the course. However, some tools have premium features, and if you wish to unlock these additional features, you may need to pay for them."
    },
    {
      question: "What Android versions can be hacked using CRAX RAT?",
      answer: "This course focuses on exploiting the latest Android versions, including Android 14. You will learn how to control Android devices within your network and remotely, regardless of the Android version."
    },
    {
      question: "What is 'Payload Binding,' and why is it important?",
      answer: "Payload binding refers to the process of merging a hacking payload (malicious code) with a legitimate application to execute exploits. This course covers auto-binding, manual binding using CRAX RAT, and custom binding with APKTool. It’s a crucial technique for bypassing security defenses and maintaining control over the target Android device."
    },
    {
      question: "What are 'Fully Undetectable Payloads (FUD)' and why should I learn them?",
      answer: "FUD refers to payloads that cannot be detected by antivirus software. In this course, you’ll learn how to create fully undetectable payloads that allow you to perform exploits on Android devices without triggering security alerts. This is a key skill for ethical hackers who need to test the effectiveness of antivirus solutions."
    },
    {
      question: "What are the post-exploitation techniques I will learn?",
      answer: "Post-exploitation refers to actions you take after gaining access to a device. In this course, you will learn advanced post-exploitation techniques such as keylogging, GPS tracking, and undetected data extraction. These techniques allow you to maintain long-term access to the target device."
    },
    {
      question: "Will I get hands-on experience?",
      answer: "Yes, this course includes step-by-step video tutorials and practical exercises where you’ll get hands-on experience using CRAX RAT, APKTool, and other hacking tools. Each module is designed to ensure that you apply what you’ve learned in real-world scenarios."
    },
    {
      question: "Is this course legal and ethical?",
      answer: "Absolutely! This course is designed for ethical hackers and cybersecurity professionals who want to test and improve security defenses on Android devices. You should only use the skills learned in this course on devices or systems for which you have explicit permission."
    },
    {
      question: "How long will I have access to the course?",
      answer: "Once you enroll, you will have lifetime access (3 years) to the course content. You can complete the course at your own pace, revisit the material, and get access to future updates."
    }
];


  return (
    <div className="faq_section">
      <div className="faq_content">
        <h2 className="faq_title">Frequently Asked Questions</h2>
        <div className="faq_container">
          {faqData.map((faq, index) => (
            <div 
              key={index} 
              className={`faq_item ${openIndex === index ? 'faq_open' : ''}`}
            >
              <div 
                className="faq_question" 
                onClick={() => toggleQuestion(index)}
              >
                <h3>{faq.question}</h3>
                <div className="faq_icon-wrapper">
                  {openIndex === index ? (
                    <Minus className="faq_icon" />
                  ) : (
                    <Plus className="faq_icon" />
                  )}
                </div>
              </div>
              <div className="faq_answer">
                <p>{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      <style jsx>{`
        .faq_section {
          background-color: #000000;
          color: #ffffff;
          padding: 80px 0;
          font-family: 'Polysans Neutral', sans-serif;
        }
        .faq_content {
          max-width: 1200px;
          margin: 0 auto;
          padding: 0 20px;
        }
        .faq_title {
          font-size: 36px;
          font-weight: 600;
          text-align: center;
          margin-bottom: 50px;
          color: #ff4444;
          letter-spacing: -0.5px;
          text-shadow: 0 2px 4px rgba(255, 68, 68, 0.1);
        }
        .faq_container {
          max-width: 800px;
          margin: 0 auto;
        }
        .faq_item {
          background-color: #111111;
          border-radius: 12px;
          margin-bottom: 20px;
          overflow: hidden;
          transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }
        .faq_item:hover {
          transform: translateY(-2px);
          box-shadow: 0 6px 12px rgba(255, 68, 68, 0.1);
        }
        .faq_question {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 24px;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }
        .faq_question:hover {
          background-color: #1a1a1a;
        }
        .faq_question h3 {
          font-size: 18px;
          font-weight: 500;
          margin: 0;
          flex: 1;
          padding-right: 20px;
          line-height: 1.4;
          transition: color 0.3s ease;
        }
        .faq_open .faq_question h3 {
          color: #ff4444;
        }
        .faq_icon-wrapper {
          background-color: rgba(255, 68, 68, 0.1);
          border-radius: 50%;
          padding: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease;
        }
        .faq_open .faq_icon-wrapper {
          background-color: #ff4444;
        }
        .faq_icon {
          width: 20px;
          height: 20px;
          color: #ff4444;
          transition: all 0.3s ease;
        }
        .faq_open .faq_icon {
          color: #ffffff;
          transform: rotate(180deg);
        }
        .faq_answer {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.3s cubic-bezier(0.25, 0.8, 0.25, 1), padding 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
          background-color: #1a1a1a;
        }
        .faq_open .faq_answer {
          max-height: 1000px;
          padding: 0 24px 24px;
        }
        .faq_answer p {
          color: #cccccc;
          line-height: 1.6;
          margin: 0;
          font-size: 16px;
          opacity: 0;
          transform: translateY(-10px);
          transition: opacity 0.3s ease 0.1s, transform 0.3s ease 0.1s;
        }
        .faq_open .faq_answer p {
          opacity: 1;
          transform: translateY(0);
          margin-top: 21px;
        }
        @media (max-width: 768px) {
          .faq_section {
            padding: 60px 0;
          }
          .faq_content {
            padding: 0 15px;
          }
          .faq_title {
            font-size: 28px;
            margin-bottom: 40px;
          }
          .faq_question {
            padding: 20px;
          }
          .faq_question h3 {
            font-size: 16px;
          }
          .faq_icon-wrapper {
            padding: 6px;
          }
          .faq_icon {
            width: 18px;
            height: 18px;
          }
          .faq_open .faq_answer {
            padding: 0 20px 20px;
          }
          .faq_answer p {
            font-size: 14px;
          }
        }
        @media (max-width: 480px) {
          .faq_title {
            font-size: 24px;
          }
          .faq_question {
            padding: 16px;
          }
          .faq_question h3 {
            font-size: 15px;
          }
        }
      `}</style>
    </div>
  );
};

export default FAQAccordion;