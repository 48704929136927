import React, { useState, useEffect } from 'react';
import { Lock, Target, Award, Book } from 'lucide-react';
import AnimatedCTAButton from './AnimatedCTAButton';

const EnhancedLandingSection = () => {
  const [activeFeature, setActiveFeature] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveFeature((prev) => (prev + 1) % 4);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const features = [
    { icon: Lock, title: "Cutting-Edge Security Techniques", description: "Master latest Android security protocols and ethical hacking methods." },
    { icon: Target, title: "Real-World Application", description: "Apply skills with hands-on projects and real-world scenarios." },
    { icon: Award, title: "Master Your Android Hacking Skills", description: "Gain expertise in Android hacking and unlock new career opportunities." },
    { icon: Book, title: "Comprehensive Course Material", description: "Access premium content and step-by-step Android exploitation tutorials." }
  ];

  return (
    <section className="landingSection">
      <div className="landingContainer">
        <div className="heroContent">
          <h1 className="heroTitle">
            Unlock Expert-Level <span className="heroTitleAccent">Android Hacking Skills</span>
          </h1>
          <p className="heroSubtitle">
            Gain advanced knowledge in Android exploitation and ethical hacking. Learn practical, in-demand cybersecurity skills.
          </p>
          <AnimatedCTAButton 
            text="Enroll Now - Only ₹1100"
            href="https://web.tevh.in/crax-rat"
            className="landing-cta-button"
          />
        </div>

        <div className="videoContainer">
          <div className="videoBorderGradient">
            <div className="videoWrapper">
              <div className="videoInnerBorder">
                <iframe 
                  src="https://www.youtube.com/embed/Zyrd_3WtfhA?si=DAPTHRXfq7Jv4lc1" 
                  title="Course Preview Video" 
                  frameBorder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  referrerPolicy="strict-origin-when-cross-origin" 
                  allowFullScreen
                  className="demoVideo"
                />
              </div>
            </div>
          </div>
          <div className="videoLabel">Preview Our Course Content</div>
        </div>

        <div className="featuresGrid">
          {features.map((feature, index) => (
            <div 
              key={index} 
              className={`featureCard ${activeFeature === index ? 'active' : ''}`}
              onMouseEnter={() => setActiveFeature(index)}
            >
              <div className="featureIconWrapper">
                <feature.icon className="featureIcon" />
              </div>
              <h3 className="featureTitle">{feature.title}</h3>
              <p className="featureDescription">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      <style jsx>{`
        .landingSection {
          background-color: #0a0a0a;
          padding: 120px 0;
          position: relative;
          overflow: hidden;
        }
        .landingSection::before {
          content: '';
          position: absolute;
          top: -50%;
          left: -50%;
          width: 200%;
          height: 200%;
          background: linear-gradient(45deg, rgba(255, 0, 0, 0.05), rgba(255, 51, 51, 0.05));
          animation: gradientShift 15s ease infinite;
          z-index: 1;
        }
        @keyframes gradientShift {
          0% { transform: translate(0, 0); }
          50% { transform: translate(-30px, -30px); }
          100% { transform: translate(0, 0); }
        }
        .landingContainer {
          max-width: 1200px;
          margin: 0 auto;
          padding: 0 40px;
          position: relative;
          z-index: 2;
        }
        .heroContent {
          text-align: center;
          margin-bottom: 40px;
        }
        .heroTitle {
          font-size: 4rem;
          color: #ffffff;
          margin-bottom: 24px;
          font-weight: 800;
          line-height: 1.2;
          letter-spacing: -0.5px;
        }
        .heroTitleAccent {
          background: linear-gradient(45deg, #ff0000, #ff3333);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          position: relative;
          display: inline-block;
        }
        .heroTitleAccent::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 5px;
          width: 100%;
          height: 3px;
          background: linear-gradient(45deg, #ff0000, #ff3333);
          transform: scaleX(0);
          transform-origin: left;
          transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
        }
        .heroTitle:hover .heroTitleAccent::after {
          transform: scaleX(1);
        }
        .heroSubtitle {
          font-size: 1.2rem;
          color: #d0d0d0;
          margin-bottom: 40px;
          line-height: 1.6;
          max-width: 700px;
          margin-left: auto;
          margin-right: auto;
        }
        .landing-cta-button {
          margin: 0 auto;
          display: block;
          max-width: 250px !important;
        }
        .landing-cta-button :global(a) {
          width: 100% !important;
          max-width: 100% !important;
          height: 60px !important;
          font-size: 18px !important;
          border-radius: 12px !important;
          background-color: #ff0000 !important;
          box-shadow: 0 8px 16px rgba(255, 0, 0, 0.3) !important;
          transition: all 0.3s ease !important;
        }
        .landing-cta-button :global(a:hover) {
          transform: translateY(-3px) scale(1.02) !important;
          box-shadow: 0 12px 24px rgba(255, 0, 0, 0.4) !important;
          background-color: #ff1a1a !important;
        }

        .videoContainer {
          max-width: 900px;
          margin: 80px auto;
          padding: 0 20px;
          position: relative;
        }

        .videoBorderGradient {
          padding: 2px;
          background: linear-gradient(
            45deg,
            rgba(255, 0, 0, 0.8),
            rgba(255, 51, 51, 0.4),
            rgba(255, 0, 0, 0.8)
          );
          border-radius: 24px;
          animation: borderGlow 3s ease-in-out infinite;
          transition: transform 0.3s ease;
        }

        .videoBorderGradient:hover {
          transform: translateY(-8px);
          animation: borderGlowIntense 3s ease-in-out infinite;
        }

        .videoWrapper {
          position: relative;
          background: rgba(10, 10, 10, 0.9);
          border-radius: 22px;
          padding: 20px;
          overflow: hidden;
        }

        .videoInnerBorder {
          position: relative;
          border-radius: 12px;
          overflow: hidden;
          box-shadow: 0 8px 32px rgba(255, 0, 0, 0.2);
          aspect-ratio: 16/9;
        }

        .demoVideo {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 12px;
          background: #000;
        }

        .videoLabel {
          text-align: center;
          color: #ffffff;
          font-size: 1.2rem;
          font-weight: 600;
          margin-top: 20px;
          text-transform: uppercase;
          letter-spacing: 1px;
          opacity: 0.9;
          text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        }

        @keyframes borderGlow {
          0% {
            box-shadow: 0 0 20px rgba(255, 0, 0, 0.3);
          }
          50% {
            box-shadow: 0 0 40px rgba(255, 0, 0, 0.5);
          }
          100% {
            box-shadow: 0 0 20px rgba(255, 0, 0, 0.3);
          }
        }

        @keyframes borderGlowIntense {
          0% {
            box-shadow: 0 0 30px rgba(255, 0, 0, 0.4);
          }
          50% {
            box-shadow: 0 0 60px rgba(255, 0, 0, 0.6);
          }
          100% {
            box-shadow: 0 0 30px rgba(255, 0, 0, 0.4);
          }
        }

        .featuresGrid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 40px;
        }
        .featureCard {
          background: rgba(255, 255, 255, 0.03);
          border-radius: 20px;
          padding: 40px;
          transition: all 0.3s ease;
          position: relative;
          overflow: hidden;
        }
        .featureCard.active, .featureCard:hover {
          background: rgba(255, 0, 0, 0.1);
          transform: translateY(-5px);
        }
        .featureIconWrapper {
          background: rgba(255, 51, 51, 0.1);
          width: 80px;
          height: 80px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 24px;
          transition: all 0.3s ease;
        }
        .featureCard.active .featureIconWrapper, .featureCard:hover .featureIconWrapper {
          background: rgba(255, 51, 51, 0.2);
          transform: scale(1.1) rotate(5deg);
        }
        .featureIcon {
          width: 40px;
          height: 40px;
          color: #ff3333;
        }
        .featureTitle {
          font-size: 1.5rem;
          color: #ffffff;
          font-weight: 700;
          margin-bottom: 16px;
        }
        .featureDescription {
          font-size: 1rem;
          color: #b0b0b0;
          line-height: 1.6;
        }

        @media (max-width: 1024px) {
          .heroTitle {
            font-size: 3.5rem;
          }
          .featuresGrid {
            grid-template-columns: 1fr;
          }
          .videoContainer {
            max-width: 800px;
            margin: 60px auto;
          }
        }

        @media (max-width: 768px) {
          .landingSection {
            padding: 80px 0;
          }
          .heroTitle {
            font-size: 3rem;
          }
          .heroSubtitle {
            font-size: 1.1rem;
          }
          .landing-cta-button :global(a) {
            height: 54px !important;
            font-size: 16px !important;
          }
          .featureCard {
            padding: 30px;
          }
          .videoContainer {
            max-width: 90%;
            margin: 40px auto;
          }
          .videoWrapper {
            padding: 15px;
          }
          .videoLabel {
            font-size: 1.1rem;
          }
        }

        @media (max-width: 480px) {
          .heroTitle {
            font-size: 2.5rem;
          }
          .heroSubtitle {
            font-size: 1rem;
          }
          .landing-cta-button :global(a) {
            height: 50px !important;
            font-size: 15px !important;
          }
          .featureIconWrapper {
            width: 60px;
            height: 60px;
          }
          .featureIcon {
            width: 30px;
            height: 30px;
          }
          .featureTitle {
            font-size: 1.3rem;
          }
          .videoContainer {
            padding: 0 15px;
            margin: 30px auto;
          }
          .videoWrapper {
            padding: 10px;
          }
          .videoBorderGradient {
            padding: 1.5px;
          }
          .videoLabel {
            font-size: 1rem;
            margin-top: 15px;
          }
        }
      `}</style>
    </section>
  );
};

export default EnhancedLandingSection;