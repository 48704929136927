import React, { useState, useRef, useEffect } from 'react';
import { Check } from 'lucide-react';

const AnimatedCTAButton = ({ text, href, className = '' }) => {
  const [buttonState, setButtonState] = useState('idle');
  const buttonRef = useRef(null);
  const loaderRef = useRef(null);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        setButtonState('idle');
        localStorage.removeItem('buttonState');
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Check if there's a stored state and it's not 'idle'
    const storedState = localStorage.getItem('buttonState');
    if (storedState && storedState !== 'idle') {
      setButtonState('idle');
      localStorage.removeItem('buttonState');
    }

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (buttonState === 'loading' && loaderRef.current) {
      loaderRef.current.style.strokeDasharray = '60, 180';
      loaderRef.current.style.strokeDashoffset = '60';
    }
  }, [buttonState]);

  const handleClick = (e) => {
    e.preventDefault();
    if (buttonState !== 'idle') return;

    setButtonState('loading');
    localStorage.setItem('buttonState', 'loading');
    
    setTimeout(() => {
      setButtonState('success');
      localStorage.setItem('buttonState', 'success');
      
      setTimeout(() => {
        window.location.href = href;
      }, 500);
    }, 1000);
  };

  return (
    <a 
      href={href}
      className={`animated-cta-button ${buttonState} ${className}`} 
      onClick={handleClick}
      ref={buttonRef}
    >
      <span className="button-content">
        {buttonState === 'idle' && text}
        {buttonState === 'loading' && (
          <svg className="circular-loader" viewBox="0 0 24 24">
            <circle
              ref={loaderRef}
              className="loader-path"
              cx="12"
              cy="12"
              r="10"
              fill="none"
              strokeWidth="3"
            />
          </svg>
        )}
        {buttonState === 'success' && <Check className="success-icon" />}
      </span>
      <div className="shine-effect"></div>
      
      <style jsx>{`
        .animated-cta-button {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background-color: #ff0000;
          color: white;
          text-decoration: none;
          border: none;
          padding: 0 24px;
          font-size: clamp(14px, 2.5vw, 18px);
          font-weight: 700;
          border-radius: 8px;
          cursor: pointer;
          transition: all 0.3s ease;
          box-shadow: 0 4px 6px rgba(255, 0, 0, 0.3);
          width: 100%;
          max-width: 300px;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          position: relative;
          overflow: hidden;
          height: clamp(44px, 8vw, 56px);
          line-height: 1.2;
        }
        .animated-cta-button:hover {
          background-color: #e60000;
          box-shadow: 0 6px 8px rgba(255, 0, 0, 0.4);
          transform: translateY(-2px);
        }
        .animated-cta-button:active {
          transform: translateY(1px);
          box-shadow: 0 2px 4px rgba(255, 0, 0, 0.4);
        }
        .animated-cta-button::after {
          content: "";
          position: absolute;
          top: -50%;
          left: -50%;
          width: 200%;
          height: 200%;
          background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, transparent 70%);
          opacity: 0;
          transition: opacity 0.3s ease;
        }
        .animated-cta-button:hover::after {
          opacity: 1;
        }
        .animated-cta-button.loading,
        .animated-cta-button.success {
          pointer-events: none;
        }
        .animated-cta-button.success {
          background-color: #4CAF50;
          box-shadow: 0 4px 6px rgba(76, 175, 80, 0.3);
        }
        .button-content {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          position: relative;
          z-index: 2;
        }
        .circular-loader {
          width: clamp(20px, 4vw, 24px);
          height: clamp(20px, 4vw, 24px);
          animation: rotate 1s linear infinite;
        }
        .loader-path {
          stroke: #ffffff;
          stroke-linecap: round;
          animation: dash 1s ease-in-out infinite;
        }
        .success-icon {
          width: clamp(20px, 4vw, 24px);
          height: clamp(20px, 4vw, 24px);
          animation: success-pop 0.3s ease-out;
        }
        .shine-effect {
          position: absolute;
          top: -50%;
          left: -50%;
          right: -50%;
          bottom: -50%;
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.2) 50%,
            rgba(255, 255, 255, 0) 100%
          );
          transform: translateX(-100%) rotate(25deg);
          animation: shineEffect 1.5s infinite linear;
          z-index: 1;
        }
        @keyframes rotate {
          100% { transform: rotate(360deg); }
        }
        @keyframes dash {
          0% {
            stroke-dasharray: 1, 180;
            stroke-dashoffset: 0;
          }
          50% {
            stroke-dasharray: 89, 180;
            stroke-dashoffset: -35;
          }
          100% {
            stroke-dasharray: 89, 180;
            stroke-dashoffset: -124;
          }
        }
        @keyframes success-pop {
          0% { transform: scale(0.5); opacity: 0; }
          100% { transform: scale(1); opacity: 1; }
        }
        @keyframes shineEffect {
          0% { transform: translateX(-100%) rotate(25deg); }
          100% { transform: translateX(100%) rotate(25deg); }
        }
        @media (max-width: 768px) {
          .animated-cta-button {
            max-width: 100%;
          }
        }
      `}</style>
    </a>
  );
};

export default AnimatedCTAButton;