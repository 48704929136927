import React, { useState } from 'react';
import { MonitorSmartphone, Shield, Database, GraduationCap } from 'lucide-react';

const AdjustedFeatureSection = () => {
  const [hoveredFeature, setHoveredFeature] = useState(null);

  const features = [
    {
      icon: MonitorSmartphone,
      title: "Real-Time Device Control",
      description: "Master full remote control over Android devices, including screen sharing, file access, and command execution across local and external networks.",
      color: "#00ffff"
    },
    {
      icon: Shield,
      title: "Undetectable Payloads (FUD)",
      description: "Create fully undetectable (FUD) malware that bypasses antivirus software, ensuring your exploits remain invisible and undisturbed.",
      color: "#ff00ff"
    },
    {
      icon: Database,
      title: "Advanced Post-Exploitation",
      description: "Dive deep into techniques like keylogging, GPS tracking, and file system exploration to maintain persistent access and gather valuable data.",
      color: "#ffff00"
    },
    {
      icon: GraduationCap,
      title: "Perfect for Beginners",
      description: "No prior experience needed. Clear, step-by-step instructions help you master ethical hacking with ease, from novice to expert.",
      color: "#00ff00"
    }
  ];

  return (
    <section className="featureSection_wrapper">
      <h2 className="featureSection_title">Master Advanced Techniques</h2>
      <div className="featureSection_grid">
        {features.map((feature, index) => (
          <div 
            key={index} 
            className="featureSection_card"
            onMouseEnter={() => setHoveredFeature(index)}
            onMouseLeave={() => setHoveredFeature(null)}
          >
            <div className="featureSection_iconWrapper" style={{ backgroundColor: feature.color + '22' }}>
              <feature.icon className="featureSection_icon" style={{ color: feature.color }} />
            </div>
            <h3 className="featureSection_cardTitle">{feature.title}</h3>
            <p className="featureSection_description">{feature.description}</p>
            <div className="featureSection_background" style={{ 
              opacity: hoveredFeature === index ? 0.1 : 0,
              backgroundColor: feature.color 
            }}></div>
          </div>
        ))}
      </div>

      <style jsx>{`
        .featureSection_wrapper {
          background-color: #0a0a0a;
          padding: 60px 5vw 100px;
          position: relative;
          overflow: hidden;
        }
        .featureSection_wrapper::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(to bottom, rgba(10, 10, 10, 0) 0%, #0a0a0a 100%);
          pointer-events: none;
        }
        .featureSection_title {
          font-size: 2.5rem;
          color: #ffffff;
          text-align: center;
          margin-bottom: 60px;
          position: relative;
          z-index: 2;
          text-transform: uppercase;
          letter-spacing: 3px;
          font-weight: 800;
          text-shadow: 0 0 20px rgba(255,255,255,0.1);
        }
        .featureSection_grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
          gap: 40px;
          max-width: 1400px;
          margin: 0 auto;
          position: relative;
          z-index: 2;
        }
        .featureSection_card {
          background: rgba(30, 30, 30, 0.6);
          border-radius: 20px;
          padding: 40px 30px;
          text-align: center;
          transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
          position: relative;
          overflow: hidden;
          backdrop-filter: blur(15px);
          border: 1px solid rgba(255,255,255,0.1);
          box-shadow: 0 10px 30px rgba(0,0,0,0.2);
        }
        .featureSection_card:hover {
          transform: translateY(-10px) scale(1.02);
          box-shadow: 0 20px 40px rgba(0,0,0,0.3);
        }
        .featureSection_iconWrapper {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto 25px;
          transition: all 0.3s ease;
        }
        .featureSection_card:hover .featureSection_iconWrapper {
          transform: scale(1.1) rotate(5deg);
        }
        .featureSection_icon {
          width: 40px;
          height: 40px;
          transition: all 0.3s ease;
        }
        .featureSection_card:hover .featureSection_icon {
          transform: scale(1.2);
        }
        .featureSection_cardTitle {
          color: #ffffff;
          font-size: 1.6rem;
          margin-bottom: 15px;
          font-weight: 700;
          transition: all 0.3s ease;
        }
        .featureSection_card:hover .featureSection_cardTitle {
          transform: scale(1.05);
          text-shadow: 0 0 10px rgba(255,255,255,0.3);
        }
        .featureSection_description {
          color: #b0b0b0;
          font-size: 1rem;
          line-height: 1.6;
          transition: all 0.3s ease;
        }
        .featureSection_card:hover .featureSection_description {
          color: #ffffff;
        }
        .featureSection_background {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          transition: all 0.4s ease;
          z-index: -1;
        }
        @media (max-width: 768px) {
          .featureSection_wrapper {
            padding: 40px 20px 80px;
          }
          .featureSection_title {
            font-size: 2rem;
            margin-bottom: 40px;
          }
          .featureSection_grid {
            grid-template-columns: 1fr;
          }
        }
        @media (min-width: 769px) and (max-width: 1200px) {
          .featureSection_grid {
            grid-template-columns: repeat(2, 1fr);
          }
        }
      `}</style>
    </section>
  );
};

export default AdjustedFeatureSection;